div.container.is-fluid {
  height: 100vh;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-font-small {
  font-size: x-small;
}

.column.buttons.has-addons {
  margin-bottom: 0rem;
}

.attention {
  color: #b52b3c;
}

.pagination {
  width: 40%;
}

@import "~@fortawesome/fontawesome-free/css/all.min.css";

$modal-content-width: 450px;
@import "~bulma";
@import "~@creativebulma/bulma-tooltip";
@import "~@creativebulma/bulma-badge";
